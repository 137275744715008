import React, { useState } from 'react'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import './Header.css'
import { Link } from 'react-scroll'
export const Header = () => {
  const mobile = window.innerWidth<=768 ? true:false;
  const [menuOpen,setMenuOpen] = useState(false)
  return (
    <div className="header" id='header'>
        <img src={Logo} alt="" className='logo' />
        {menuOpen === false && mobile === true?(
          <div onClick={()=>setMenuOpen(true)}>
             <img src={Bars} alt="" style={{width:'1.5rem', height:'1.5rem'}}/>
          </div>
        ):(
          <ul className='header-menu'>
            <li >
              <Link
              onClick={()=>setMenuOpen(false)}
              span={true}
              activeClass='active'
              smooth={true}
              spy={true}
              to='header'>Home</Link>
              
              </li>
            <li>
              <Link to='programs'
              onClick={()=>setMenuOpen(false)}
              span={true}
              smooth={true}
              spy={true}
              >Programs</Link>
              
              </li>
            <li>
              <Link
              onClick={()=>setMenuOpen(false)}
              span={true}
              smooth={true}
              spy={true}
              to='reasons'> Why Us</Link>
             </li>
            <li>
              <Link to='plan'
               onClick={()=>setMenuOpen(false)}
               span={true}
              smooth={true}
              spy={true}> Plans</Link>
             </li>
            <li onClick={()=>setMenuOpen(false)}>
              <Link 
              onClick={()=>setMenuOpen(false)}
              span={true}
              smooth={true}
              to='testimonials'>Testimonials</Link>
              
              </li>
        </ul>
        )}
        
    </div>
  )
}
